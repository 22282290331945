import type { CSSProperties } from 'vue';
import { getAvailableSimulationDevices } from '../hooks/useDevice';
import { CampaignAdsSizeType } from '../typings/enums/enums';
import { getAdminUiEndpoint } from './Url';
import { useUtilityStore } from '@/src/store/utility';
import { Cache } from '@/src/services/cache';
import type { ReplacementTags } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';

export const styleObjectToCSS = (styleObj: CSSProperties): string => {
  return Object.entries(styleObj)
    .map(([key, value]) => {
      const cssKey = key.replace(/([A-Z])/g, (match) => `-${match.toLowerCase()}`);
      return `${cssKey}: ${value}`;
    })
    .join('; ');
};

export const debounce = (func: () => void, wait: number, immediate?: boolean) => {
  let timeout: number | undefined | null;
  return function () {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const later = function () {
      timeout = null;
      // @ts-ignore
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    if (timeout) {
      window.clearTimeout(timeout);
    }
    timeout = window.setTimeout(later, wait);
    // @ts-ignore
    if (callNow) func.apply(context, args);
  };
};

export const convertHTMLEntities = (str: string) => {
  return str.replace(/&#(\d+);/g, (_match, dec) => {
    return String.fromCharCode(dec);
  });
};

/**
 * Converts a string to Base64 after encoding it as UTF-8.
 *
 * Uses TextEncoder to convert the string to UTF-8 bytes, builds a binary string,
 * and then encodes that binary string to Base64.
 */
export const utf8ToB64 = (str: string) => {
  const utf8Bytes = new TextEncoder().encode(str);
  let binary = '';
  utf8Bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
};

/**
 * Shuffles string in place.
 */
export const shuffleWord = (s: string) => {
  const a = s.split('');
  const n = a.length;

  for (let i = n - 1; i > 0; i--) {
    // nosem: ajinabraham.njsscan.crypto_node.node_insecure_random_generator
    const j = Math.floor(Math.random() * (i + 1));
    // eslint-disable-next-line security/detect-object-injection
    const tmp = a[i];
    // eslint-disable-next-line security/detect-object-injection
    a[i] = a[j];
    // eslint-disable-next-line security/detect-object-injection
    a[j] = tmp;
  }
  return a.join('');
};

/**
 * Shuffles array in place.
 */
export const shuffle = <T>(a: Array<T>) => {
  for (let i = a.length - 1; i > 0; i--) {
    // nosem: ajinabraham.njsscan.crypto_node.node_insecure_random_generator
    const j = Math.floor(Math.random() * (i + 1));
    // eslint-disable-next-line security/detect-object-injection
    const x = a[i];
    a[Number(i)] = a[Number(j)];
    a[Number(j)] = x;
  }

  return a;
};

export const hexToRGBA = (hex: string, alpha: string | number) => {
  if (hex.length === 4) {
    hex += hex.substring(1);
  }
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

export const generateUniqueId = (): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 8; i++) {
    // nosem: ajinabraham.njsscan.crypto_node.node_insecure_random_generator
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getYouTubeVideoIdFromUrl = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : undefined;
};

export const isFacebookApp = () => {
  // @ts-ignore
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  if (ua.includes('FBAN') || ua.includes('FBAV')) {
    const isMessenger = ua.includes('Messenger');
    if (isMessenger) {
      return false;
    }
    return true;
  }
  return false;
};

export const getVimeoIdFromUrl = (url: string) => {
  // Look for a string with 'vimeo', then whatever, then a
  // forward slash and a group of digits.
  const match = /vimeo.*\/(\d+)/i.exec(url);
  // If the match isn't null (i.e. it matched)
  if (match) {
    // The grouped/matched digits from the regex
    return match[1];
  }
};

export const generateYouTubeUrl = (videoId: string): string => {
  return `//www.youtube.com/embed/${videoId}?autoplay=1&autohide=1&showinfo=0&modestbranding=1&controls=0&mute=0&rel=0&enablejsapi=1`;
};

export const generateVimeoUrl = (videoId: string): string => {
  return `https://player.vimeo.com/video/${videoId}?&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1`;
};

export const removeCharsFromString = (str: string, numOfChars: number): number => {
  return Number(str.slice(0, -numOfChars));
};

export const formatColor = (color: string) => {
  if (color && !color.includes('#') && !color.includes('rgba') && !color.includes('transparent')) {
    return '#' + color;
  }
  return color;
};

/**
 * This function will adjust the brightness of a color to make it lighter or darker
 * @param color (string)
 * @param steps (number)
 */
export const adjustBrightness = (color: string, steps: number) => {
  if (color.includes('rgb')) {
    return color;
  }

  steps = Math.max(-255, Math.min(255, steps));
  color = color.replace('#', '');

  let sixDigitsHex = '';

  if (color.length !== 3) {
    sixDigitsHex = color;
  } else {
    color.split('').forEach((el) => {
      sixDigitsHex += el + el;
    });
  }

  let R = parseInt(sixDigitsHex.substring(0, 2), 16);
  let G = parseInt(sixDigitsHex.substring(2, 4), 16);
  let B = parseInt(sixDigitsHex.substring(4, 6), 16);

  R = Math.max(0, Math.min(255, R + steps));
  G = Math.max(0, Math.min(255, G + steps));
  B = Math.max(0, Math.min(255, B + steps));

  const RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

  return `#${RR}${GG}${BB}`;
};

/**
 * Takes a string like "A good job" and turns it into "a-good-job"
 */
export const stringToSlug = (str: string): string => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();
  // remove accents, swap ñ for n, etc
  const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    // eslint-disable-next-line security/detect-non-literal-regexp
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)); // nosemgrep
  }
  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes
  return str;
};

export const setDesktopBodyClasses = (isSimulating = false) => {
  const utilityStore = useUtilityStore();

  const classesToAdd = ['site', 'site--desktop', 'category-landingpage', 'category-desktop'];
  const classesToRemove = ['site--tablet', 'site--mobile', 'category-tablet', 'category-mobile'];

  if (isSimulating && !inIframe()) {
    classesToAdd.push('site--simulate-desktop');
    classesToRemove.push('site--simulate-tablet', 'site--simulate-mobile');
  }

  utilityStore.addRemoveClassList(classesToAdd, classesToRemove);
};

export const setTabletBodyClasses = (isSimulating = false) => {
  const utilityStore = useUtilityStore();

  const classesToAdd = ['site', 'category-landingpage', 'site--tablet', 'category-tablet'];
  const classesToRemove = ['site--desktop', 'site--mobile', 'category-desktop', 'category-mobile'];

  if (isSimulating && !inIframe()) {
    classesToAdd.push('site--simulate-tablet');
    classesToRemove.push('site--simulate-desktop', 'site--simulate-mobile');
  }

  utilityStore.addRemoveClassList(classesToAdd, classesToRemove);
};
export const setMobileBodyClasses = (isSimulating = false) => {
  const utilityStore = useUtilityStore();

  const classesToAdd = ['site', 'category-landingpage', 'site--mobile', 'category-mobile'];
  const classeToRemove = ['site--desktop', 'site--tablet', 'category-desktop', 'category-tablet'];

  if (isSimulating && !inIframe()) {
    classesToAdd.push('site--simulate-mobile');
    classeToRemove.push('site--simulate-desktop', 'site--simulate-tablet');
  }

  utilityStore.addRemoveClassList(classesToAdd, classeToRemove);
};

export const setAdsBodyclasses = () => {
  const utilityStore = useUtilityStore();
  const campaignStore = useCampaignStore();

  const classesToAdd = ['site', 'site--ads', 'category-landingpage'];
  const classeToRemove = [
    'site--desktop',
    'site--mobile',
    'site--tablet',
    'category-desktop',
    'category-tablet',
    'category-mobile'
  ];

  const availableSizes = (getAvailableSimulationDevices()[0]?.sizes ?? []).filter(
    (size) => size.dimensions !== undefined
  );

  // Make sure to remove all other sizes from the body than the current
  // simulated view.
  classeToRemove.push(
    ...availableSizes
      .filter((size) => size.id !== campaignStore.currentSimulatedView?.view)
      .map((size) => {
        return `site--ads-size-${size.id}`;
      })
  );

  if (campaignStore.currentSimulatedView?.view) {
    classesToAdd.push(`site--ads-size-${campaignStore.currentSimulatedView.view}`);
  }

  if (campaignStore.model?.state.adsSizeType !== undefined) {
    switch (campaignStore.model.state.adsSizeType) {
      case CampaignAdsSizeType.FIXED:
        classesToAdd.push('site--ads-type-fixed');
        break;

      case CampaignAdsSizeType.RESPONSIVE:
        classesToAdd.push('site--ads-type-responsive');
        break;
    }
  }

  utilityStore.addRemoveClassList(classesToAdd, classeToRemove);
};

/**
 * Copied over from the old source code. Originates from:
 * https://locutus.io/php/strings/number_format/
 */
export const numberFormat = (number: number | string, decimals: number, decPoint?: string, thousandsSep?: string) => {
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');

  const n = !isFinite(+number) ? 0 : +number;
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
  const dec = typeof decPoint === 'undefined' ? '.' : decPoint;

  const toFixedFix = (n: number, prec: number) => {
    if (!('' + n).includes('e')) {
      return +(Math.round(Number(n + 'e+' + prec)) + 'e-' + prec);
    } else {
      const arr = ('' + n).split('e');
      let sig = '';

      if (+arr[1] + prec > 0) {
        sig = '+';
      }

      return (+(Math.round(Number(+arr[0] + 'e' + sig + (+arr[1] + prec))) + 'e-' + prec)).toFixed(prec);
    }
  };

  const split = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.');

  if (split[0].length > 3) {
    // eslint-disable-next-line security/detect-unsafe-regex
    split[0] = split[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }

  if ((split[1] || '').length < prec) {
    split[1] = split[1] || '';
    split[1] += new Array(prec - split[1].length + 1).join('0');
  }

  return split.join(dec);
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const hasNumberValue = (value: number) => {
  return !isNaN(value);
};

/**
 * @deprecated This should be considered deprecated and might be removed in future versions. Uses unsafe innerHTML.
 */
export const stripHtml = (html: string) => {
  // Create a new div element
  const temporalDivElement = document.createElement('div');
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html; // nosem
  // Retrieve the text property of the element (cross-browser support)
  return temporalDivElement.textContent || temporalDivElement.innerText || '';
};

/**
 * We have scenarios where we get an id back from fonts. This is a reference to a global
 * font, so we need to look up the font in custom fonts, and get the name of the font.
 */
export const replaceFontIdWithFontString = (fontFamily: string) => {
  const campaignStore = useCampaignStore();
  const campaignState = campaignStore.model?.state;

  if (fontFamily && fontFamily.includes('custom:')) {
    const fontId = Number(fontFamily.replace('custom:', ''));
    if (campaignState?.config?.customFonts) {
      const font = campaignState?.config.customFonts.find((font) => {
        return font.id === fontId;
      });
      return font?.fontFamily;
    }
  } else {
    const font = campaignState?.config?.customFonts.find((font) => {
      return font.name === fontFamily;
    });
    return font?.fontFamily ?? fontFamily;
  }

  return fontFamily;
};

/**
 * This function will take a string input and replace all replacementTags, such as #title# with
 * a given replacementtag. Eg: Hi my name is #name# - Then #name# would be replaced with the actual name
 */

export const applyReplacementTags = (value: string, extraReplacementTags?: ReplacementTags): string => {
  const campaignStore = useCampaignStore();

  if (campaignStore.model?.state.isEditModeActive) {
    return value;
  }

  const replacementTags = campaignStore.replacementTags;

  // makes sure we replace deep nested replacement tags
  // etc we got replacementtags inside the description replacementtag
  for (let i = 0; i <= 2; i++) {
    if (typeof extraReplacementTags !== 'undefined') {
      for (const replacementKey in extraReplacementTags) {
        if (
          Object.prototype.hasOwnProperty.call(extraReplacementTags, replacementKey) &&
          value.toLowerCase().includes(replacementKey.toLowerCase())
        ) {
          value = value.replace(
            // eslint-disable-next-line security/detect-non-literal-regexp
            new RegExp(`#${replacementKey}#`, 'gi'), // nosem
            extraReplacementTags[`${replacementKey}`].toString()
          );
        }
      }
    }

    for (const replacementKey in replacementTags) {
      if (
        Object.prototype.hasOwnProperty.call(replacementTags, replacementKey) &&
        value.toLowerCase().includes(replacementKey.toLowerCase())
      ) {
        value = value.replace(
          // eslint-disable-next-line security/detect-non-literal-regexp
          new RegExp(`#${replacementKey}#`, 'gi'), // nosem
          replacementTags[`${replacementKey}`].toString()
        );
      }
    }
  }

  return value.replace(/#[a-z0-9\-_]+#/gi, '');
};

export function initFacebookSdk(id: string) {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).fbAsyncInit = function () {
      // @ts-ignore
      FB.init({
        appId: id,
        cookie: true,
        xfbml: true,
        version: 'v2.8'
      });
    };
  }

  // load facebook sdk script
  if (typeof document !== 'undefined') {
    (function (d, s, id) {
      // eslint-disable-next-line prefer-const
      let fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      // @ts-ignore
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }
}

/**
 * Returns the height of the current "document/browser window"
 */
export const getDocumentHeight = () => {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  );
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    return true;
  }
};

// Get a random value between two numbers exluding to and from
// etc. 1-5 = 2,3,4
export const randomize = (from: number, to: number): number => {
  // nosem: ajinabraham.njsscan.crypto_node.node_insecure_random_generator
  return Math.floor(Math.random() * to) + from;
};

// Get a random value between two numbers allowing min and max
// etc. 1-5 = 1,2,3,4,5
export const randomNumberBetween = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

// Utility method for getting event containing pageX/pageY properties
export const getTouch = (e: TouchEvent | MouseEvent): Touch | MouseEvent => {
  if (window.TouchEvent && e instanceof TouchEvent) {
    return e.touches[0];
  } else {
    return e as MouseEvent;
  }
};

export enum StringUnit {
  PERCENT,
  PIXEL,
  VIEW_HEIGHT
}

export const unitToString = (value: number, unit: StringUnit) => {
  switch (unit) {
    case StringUnit.PERCENT:
      return `${value}%`;
    case StringUnit.PIXEL:
      return `${value}px`;
    case StringUnit.VIEW_HEIGHT:
      return `${value}vh`;
    default:
      return '';
  }
};

export const extractUnitFromSize = (size: string): StringUnit | undefined => {
  if (size.includes('%')) {
    return StringUnit.PERCENT;
  } else if (size.includes('px')) {
    return StringUnit.PIXEL;
  } else if (size.includes('vh')) {
    return StringUnit.VIEW_HEIGHT;
  }
};

export const transformMeasurementToNumber = (data: string, defaultValue = 0): number => {
  if (data) {
    if (data.includes('%')) {
      return removeCharsFromString(data, 1);
    } else if (data.includes('px')) {
      return removeCharsFromString(data, 2);
    } else if (data.includes('vh')) {
      return removeCharsFromString(data, 2);
    } else {
      return defaultValue;
    }
  }
  return defaultValue;
};

interface CacheItem {
  promise: Promise<HTMLImageElement>;
  loaded: boolean;
  element: HTMLImageElement;
}

export const preloadImagePromise = (image: string, crossOrigin?: 'anonymous' | 'use-credentials') => {
  const cacheKey = `${image}${crossOrigin}`;
  const cacheValue = Cache.get<CacheItem>(cacheKey);

  if (cacheValue) {
    if (!cacheValue.loaded) {
      return cacheValue.promise; // Return the existing promise if still loading
    }
    return Promise.resolve(cacheValue.element); // Return the loaded image if available
  }

  const img = new Image();

  if (crossOrigin) {
    img.crossOrigin = crossOrigin;
  }

  const promise = new Promise<HTMLImageElement>((resolve, reject) => {
    img.onload = () => {
      Cache.set<CacheItem>(cacheKey, {
        promise,
        element: img,
        loaded: true
      });

      resolve(img);
    };
    img.onerror = () => {
      reject(new Error(`Failed to load image: ${img.src}`));
    };
  });

  Cache.set<CacheItem>(cacheKey, {
    promise,
    element: img,
    loaded: false
  });

  img.src = image;

  return promise;
};

export const getCurrentPort = () => {
  return window.location.port;
};

export const createEditFormUrl = (url: string): string => {
  let editFormUrl = getAdminUiEndpoint() + url;
  editFormUrl += (editFormUrl.includes('?') ? '&' : '?') + 'template=iframe';

  return editFormUrl;
};

export const getScrollbarWidth = () => {
  if (Cache.exists('scrollbarWidth')) {
    return Cache.get('scrollbarWidth');
  }
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  // outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps // We no longer support IE
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  Cache.set('scrollbarWidth', outer.offsetWidth - inner.offsetWidth);

  // Removing temporary elements from the DOM
  if (outer && outer.parentNode) {
    outer.parentNode.removeChild(outer);
  }

  return Cache.get('scrollbarWidth');
};

export const getScrollY = () => {
  const scroll = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);
  return !isNaN(scroll) ? Math.abs(scroll) : 0;
};

export const truncateString = (value: string, maxlength: number) => {
  const valueReplace = value.replace(/<(?:.|\n)*?>/gm, '');
  return valueReplace.length > maxlength ? valueReplace.substring(0, maxlength - 3) + '...' : value;
};

// Shuffles array in place.
export const shuffleLetters = (words: string) => {
  const a = words.split(' ');
  const n = a.length;

  for (let i = 0; i < n; i++) {
    // eslint-disable-next-line security/detect-object-injection
    a[i] = shuffleWord(a[i]);
  }
  return a.join(' ');
};

// function to return a random index by the letters of an string
export const randomIndexes = (word: string): number[] => {
  const indArr: number[] = [];
  let randIndex: number | null = null;
  const indCount = Math.floor(word.length / 5) || 1;

  for (let i = 0; i < word.length; i++) {
    // nosem: ajinabraham.njsscan.crypto_node.node_insecure_random_generator
    randIndex = Math.floor(Math.random() * word.length);

    // eslint-disable-next-line security/detect-object-injection
    if (word[randIndex] !== ' ' && !indArr.includes(randIndex)) {
      indArr.push(randIndex);
    }

    if (indArr.length === indCount) {
      break;
    }
  }

  return indArr;
};

export const timestampToSeconds = (time: string | null) => {
  time = time || null;

  if (!time) {
    return 0;
  }

  if (!time.includes(':')) {
    return Number(time);
  }

  const splitTime = time.split(':');
  const minutes = parseInt(splitTime[0], 10);
  const seconds = parseInt(splitTime[1], 10);
  return (minutes > 0 ? minutes * 60 : 0) + seconds;
};

export const waitForImages = (el: HTMLElement, timeout = 3000): Promise<void> => {
  return new Promise<void>((resolveMainPromise) => {
    (async () => {
      let didResolve = false;

      setTimeout(() => {
        if (!didResolve) {
          didResolve = true;
          resolveMainPromise();
        }
      }, timeout);

      await Promise.all(
        Array.from(el.querySelectorAll('img'))
          .filter((img) => !!img.src)
          .map((img) => {
            return new Promise<void>((resolve) => {
              if (img.complete && img.naturalWidth) {
                resolve();
              } else {
                img.addEventListener('abort', () => {
                  resolve();
                });

                img.addEventListener('load', () => {
                  resolve();
                });

                img.addEventListener('error', () => {
                  resolve();
                });
              }
            });
          })
      );

      if (!didResolve) {
        didResolve = true;
        resolveMainPromise();
      }
    })();
  });
};

export const traverseUpUntilTarget = (currentNode: HTMLElement, targetClass: string) => {
  let node: HTMLElement | null = currentNode;

  while (node !== null) {
    if (node.classList.contains(targetClass)) {
      return node;
    }
    node = node.parentElement;
  }

  return null;
};

export const traverseUpUntil = (currentNode: HTMLElement, fn: (el: HTMLElement) => boolean) => {
  let node: HTMLElement | null = currentNode;

  while (node !== null) {
    if (fn(node)) {
      return node;
    }

    node = node.parentElement;
  }

  return null;
};

export const getScrollTop = (node: HTMLElement) => {
  let target: HTMLElement | null = node;
  let scrollTop = 0;

  while (target) {
    scrollTop += target.scrollTop;
    target = target.parentElement;
  }

  return scrollTop;
};

export const getOffsetTop = (node: HTMLElement) => {
  let target: HTMLElement | null = node;
  let offsetTop = 0;

  while (target) {
    offsetTop += target.offsetTop;
    target = target.parentElement;
  }

  return offsetTop;
};

export const copyToClipboard = (text: string) => {
  if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
    navigator.clipboard.writeText(text).catch((err) => {
      // eslint-disable-next-line no-console
      console.error('[Playable] Copy operation failed:', err);
    });
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (!successful) {
        // eslint-disable-next-line no-console
        console.error('[Playable] Copy operation failed without reason');
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('[Playable] Copy operation failed:', err);
    }

    document.body.removeChild(textArea);
  }
};

export const shuffleNumberArray = (array: number[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    // Generate a random index lower than the current index
    const j = Math.floor(Math.random() * (i + 1));

    // Swap elements at indices i and j
    [array[Number(i)], array[Number(j)]] = [array[Number(j)], array[Number(i)]];
  }
  return array;
};

export function isValidValue<T>(value: T): boolean {
  return value !== undefined && value !== null && value !== '';
}

export const setupTouchPreventionForElement = (selector: string) => {
  const preventInAppClose = (e: TouchEvent) => {
    const element = document.querySelector(selector);
    if (!element) return;

    const { top, bottom } = element.getBoundingClientRect();

    for (const touch of e.touches) {
      if (touch.clientY >= top && touch.clientY <= bottom) {
        e.preventDefault();
        break;
      }
    }
  };

  window.addEventListener('touchstart', preventInAppClose, { passive: false });

  return () => {
    window.removeEventListener('touchstart', preventInAppClose);
  };
};
